import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, catchError, take, tap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { TransferState, makeStateKey } from '@angular/core';
import { WindowService } from '../window/window.service';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {
  referencesLoaded: boolean = false;
  candidateReferencesLoaded: boolean = false;
  RecruiterReferencesLoaded: boolean = false;
  jobsList = [];

  jobsListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  contractsListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  workingTimeListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  structuresListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  structuresListPartialObs: BehaviorSubject<any> = new BehaviorSubject(null);
  remunerationListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  advantagesListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  softwaresListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  languagesListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  missionsListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  specialitiesListObs: BehaviorSubject<any> = new BehaviorSubject(null);
  subscribtionList = [];

  contractsList = [
    { label: 'CDI', value: false, uid: 'cdi', color: 'light' },
    { label: 'CDD', value: false, uid: 'cdd', color: 'light' },
    { label: 'Stage', value: false, uid: 'stage', color: 'light' },
  ]
  intermediates: string | null = null;
  intermediatesList = [
    { label: 'Tout afficher', value: false, uid: '', color: 'light' },
    { label: 'Sans intermédiaire', value: false, uid: 'no-agence', color: 'light' },
    { label: 'Agences de recrutement', value: false, uid: 'agence', color: 'light' },
  ]
  structuresList = [];
  structuresListPartial = [];
  workingTimeList = [
    { label: "Temps plein", value: false, uid: "full", color: 'light' },
    { label: "Temps partiel", value: false, uid: "partial", color: 'light' },
  ];

  languesList = [
    { label: "Francais", value: false, iso: "fr", color: 'light' },
    { label: "Anglais", value: false, iso: "an", color: 'light' },
    { label: "Allemand", value: false, iso: "al", color: 'light' },
    { label: "Espagnol", value: false, iso: "es", color: 'light' },
    { label: "Chinois", value: false, iso: "ch", color: 'light' },
    { label: "Portugais", value: false, iso: "po", color: 'light' },
  ];

  zones: any = [{ localisation: '', rayon: 20, adressesList: [], selectedAdress: false, colSize: '12' }];

  dispos = [[false, false, false], [false, false, false], [false, false, false], [false, false, false], [false, false, false], [false, false, false], [false, false, false]];

  skillsList = []

  softwaresList = []

  languageLevelList = [
    { label: "Notions élémentaires", uid: "elementary", value: false },
    { label: "Compétence professionnelle limitée", uid: "limited", value: false },
    { label: "Compétence professionnelle", uid: "operational", value: false },
    { label: "Compétence professionnelle complète", uid: "fluent", value: false },
    { label: "Bilingue ou langue natale", uid: "native", value: false }
  ]

  experienceList = [
    { label: "Pas d'expérience minimum requise", uid: "less-than-1", value: false },
    { label: "1 à 3 ans", uid: "1-to-3", value: false },
    { label: "3 à 5 ans", uid: "3-to-5", value: false },
    { label: "5 à 10 ans", uid: "5-to-10", value: false },
    { label: "plus de 10 ans", uid: "more-than-10", value: false }
  ]

  remunerationList = {
    'smic': {
      hour: 11.49,
      month: 1743,
      year: 20916
    },
    indexCoefficients: {
      '70b16584-b084-4047-b361-c7262a3b1d47': 400
    }
  }

  environmentsList = [
    { label: "Pharmacie de quartier", uid: "pharmacie-de-quartier", value: false },
    { label: "Pharmacie de station de ski", uid: "pharmacie-de-station-de-ski", value: false },
    { label: "Pharmacie de village", uid: "pharmacie-de-village", value: false },
    { label: "Pharmacie de Bourg", uid: "pharmacie-de-bourg", value: false },
    { label: "Pharmacie de centre commercial", uid: "pharmacie-de-centre-commercial", value: false },
    { label: "Pharmacie de centre ville", uid: "pharmacie-de-centre-ville", value: false },
    { label: "Pharmacie de ville", uid: "pharmacie-de-ville", value: false },
    { label: "Pharmacie de plage / bord de mer", uid: "pharmacie-de-plage/bord-de-mer", value: false },
    { label: "Pharmacie de campagne", uid: "pharmacie-de-campagne", value: false },
    { label: "Pharmacie semi-rurale", uid: "pharmacie-semi-rurale", value: false },
    { label: "Pharmacie de peripherie", uid: "pharmacie-peripherie", value: false },
    { label: "Pharmacie d Aéroport/Gare", uid: "pharmacie-d-Aéroport/Gare", value: false },
    { label: "PUI en établissement de santé", uid: "PUI-en-établissement-de-santé", value: false },
    { label: "Laboratoire de Biologie Médicale", uid: "LABM", value: false },
    { label: "Parapharmacie grande distribution", uid: "parapharmacie-grande-distribution", value: false },
    { label: "Prestataire de santé à domicile", uid: "PSAD", value: false },
    { label: "Autre type d'entreprise", uid: "Autre-entreprise", value: false }
  ];


  specialitiesList = [
  ]

  formationsList: any = null;

  advantagesList = [
    { label: "valeur 1", uid: "1", value: false },
    { label: "Valeur 2", uid: "2", value: false },
    { label: "Valeur 3", uid: "3", value: false },
    { label: "Valeur 4", uid: "4", value: false }
  ]

  missionsList = null;

  workingSaturdayList = [
    { label: "non précisé", uid: null, value: false },
    { label: "Aucun", uid: "none", value: false },
    { label: "1 sur 2", uid: "half", value: false },
    { label: "2 sur 3", uid: "two-for-three", value: false },
    { label: "1 sur 3", uid: "one-for-three", value: false },
    { label: "1 sur 4", uid: "one-for-four", value: false },
    { label: "3 sur 4", uid: "three-for-four", value: false },
    { label: "Tous", uid: "all", value: false }
  ]

  pdaList = [
    { label: 'Non', value: false, uid: 'no' },
    { label: 'Manuelle', value: false, uid: 'manual' },
    { label: 'Automatisée', value: false, uid: 'auto' },
  ]

  salaryTypesList = [
    { label: 'Brut mensuel', value: false, uid: 'monthlyBrut' },
    { label: 'Net mensuel', value: false, uid: 'monthlyNet' },
    { label: 'Brut horaire', value: false, uid: 'hourBrut' },
    { label: 'Net horaire', value: false, uid: 'hourNet' }
  ]

  evalAccuracyObs = new BehaviorSubject([
    {
      "uuid": "35f3982a-ae0d-4691-8f60-7ae8f94ace2a",
      "label": "Ne répond pas aux messages / au tel"
    },
    {
      "uuid": "a453fd8a-3759-4a55-8c84-56f70bb6e4ed",
      "label": "Candidat indisponible"
    },
    {
      "uuid": "c28f7706-ad44-4333-a1c8-a76af2a8a64c",
      "label": "N'a pas le diplôme"
    },
    {
      "uuid": "8bffaac2-a5dd-4ce7-ad71-192f9eeddc86",
      "label": "Ne s'est pas présenté(e) à l'entretien"
    },
    {
      "uuid": "199e6738-ee2e-4a46-bb4f-031108927ef6",
      "label": "Ne s'est pas présenté(e) lors de l'embauche"
    },
    {
      "uuid": "0de33f12-e814-4a25-81ef-7b44a17b5959",
      "label": "Téléphone incorrect"
    },
    {
      "uuid": "b32d1eb0-7763-492d-8355-f1f4406c225f",
      "label": "Non compétent(e)"
    },
    {
      "uuid": "782f0c0f-ff00-44dd-9a22-ab03bacef965",
      "label": "Non fiable"
    }
  ]);

  evalContactTypeObs = new BehaviorSubject([
    {
      "uuid": "0949dcc5-9389-44f3-b660-e6e7d6a41f60",
      "label": "Messagerie"
    },
    {
      "uuid": "02be08bb-34c7-4bc9-b69a-9b08a3898cdb",
      "label": "Téléphone"
    },
    {
      "uuid": "c1c5b5a5-766c-4266-ad58-a91954ef5772",
      "label": "Face à face"
    },
    {
      "uuid": "5c2fe7e5-5022-4776-9061-f52bd68e4090",
      "label": "Recruté(e)"
    }
  ]);

  salaryList = [
    {
      "uid": "0",
      "label": "Tout afficher",
      value: false
    },
    {
      "uid": "2000",
      "label": "Plus de 13.19€/h (2 000€/mois)",
      value: false
    },
    {
      "uid": "2500",
      "label": "Plus de 16.48€/h (2 500€/mois)",
      value: false
    },
    {
      "uid": "3000",
      "label": "Plus de 19.78€/h (3 000€/mois)",
      value: false
    },
    {
      "uid": "3500",
      "label": "Plus de 23.08€/h (3 500€/mois)",
      value: false
    },
    {
      "uid": "4000",
      "label": "Plus de 26.37€/h (4 000€/mois)",
      value: false
    },
    {
      "uid": "4500",
      "label": "Plus de 29.67€/h (4 500€/mois)",
      value: false
    },
    {
      "uid": "5000",
      "label": "Plus de 32.97€/h (5 000€/mois)",
      value: false
    }
  ]

  associationTypeList = [{ uid: "practicing_partner_search", label: "Avec un associé exerçant", value: false }, { uid: "investment_partner_search", label: "Avec un associé investisseur", value: false }, { uid: "solo_installation_search", label: "Seul", value: false }];
  recruiterAssociationTypeList = [{ uid: "pharmacy_invest", label: "Devenir associé investisseur", value: false }, { uid: "pharmacy_buying", label: "Acheter une officine", value: false }, { uid: "shares_resale", label: "Vendre mes parts", value: false }, { uid: "pharmacy_selling", label: "Vendre mon officine", value: false }, { uid: "gradual_exit", label: "Sortie progressive", value: false }];
  searchAssociationTypeList = [{ uid: "pharmacy_buying", label: "Officines à vendre", value: false }, { uid: "shares_resale", label: "Parts à vendre", value: false }, { uid: "pharmacy_selling", label: "Candidats à la reprise", value: false }, { uid: "pharmacy_invest", label: "Investisseurs", value: false }, { uid: "investment_partner_search", label: "Associé exerçant en recherche d'associé investisseur", value: false }, { uid: "practicing_partner_search", label: "Associé exerçant en recherche d'associé exerçant", value: false }];
  recruiterOfferAssociationTypeList = [{ uid: "pharmacy_selling", label: "Vendre mon officine", labelDetail: "Vendre la totalité de mon officine", value: false }, { uid: "gradual_exit", label: "Sortie progressive", labelDetail: "Vendre mon officine progressivement sur plusieurs années", value: false }, { uid: "shares_resale", label: "Céder des parts", labelDetail: "Vendre une partie des parts de l'officine", value: false }];



  constructor(private alertController: AlertController,
    private backApiService: BackApiService,
    private transferState: TransferState,
    private windowService: WindowService,
    private settingsService: SettingsService,
  ) {
  }

  /**
  * Load all list (uid-label) from API. Uses getListFromApi()
  */
  initListsFromApi(role: string | null = null) {
    console.log('REFERENCE SERVICE initListsFromAPI()');
    const activePage: string | null = this.settingsService.getActivePage();
    if (!this.referencesLoaded) {
      this.getListFromApi(environment.jobsList).pipe(take(1)).subscribe((res: any) => {
        this.jobsList = res['hydra:member'];
        this.jobsListObs.next(res['hydra:member']);
      });
      this.getListFromApi(environment.structuresList).pipe(take(1)).subscribe((res: any) => {
        this.structuresList = res['hydra:member'];
        this.structuresListPartial = res['hydra:member'].filter((structure: any) => structure.uid != '3ed78e56-76f9-49f6-8dcc-f6a8d41fa626' && structure.uid != 'b519db97-a3a5-485b-b86e-e7bd2c6e802c');
        this.structuresListObs.next(res['hydra:member']);
        this.structuresListPartialObs.next(res['hydra:member'].filter((structure: any) => structure.uid != '3ed78e56-76f9-49f6-8dcc-f6a8d41fa626' && structure.uid != 'b519db97-a3a5-485b-b86e-e7bd2c6e802c'));
      });
      this.getListFromApi(environment.softwaresList).pipe(take(1)).subscribe((res: any) => {
        this.softwaresList = res['hydra:member'];
        this.softwaresListObs.next(res['hydra:member']);
      });
      //  if (this.windowService.isPlatformBrowser() || activePage?.includes('rechercher/offres')) {
      this.getListFromApi(environment.formationsList).pipe(take(1)).subscribe((res: any) => {
        this.formationsList = res['hydra:member'];
      });
      this.getListFromApi(environment.languesList).pipe(take(1)).subscribe((res: any) => {
        this.languesList = res['hydra:member'];
        this.languagesListObs.next(this.languesList);
      });
      this.getListFromApi(environment.skillsList).pipe(take(1)).subscribe((res: any) => {
        this.skillsList = res['hydra:member'];
      });
      this.advantagesListObs.next(this.advantagesList);  // TO CHANGE
      this.getListFromApi(environment.specialitiesList).pipe(take(1)).subscribe((res: any) => {
        this.specialitiesList = res['hydra:member'];
        this.specialitiesListObs.next(res['hydra:member']);
      });
      //  }
    }
    this.referencesLoaded = true;


    if (role == "ROLE_RECRUITER" && !this.RecruiterReferencesLoaded) {
      this.RecruiterReferencesLoaded = true;
      this.getListFromApi(environment.missionsList).pipe(take(1)).subscribe((res: any) => {
        this.missionsList = res['hydra:member'];
        this.missionsListObs.next(res['hydra:member']);
      });

      this.getListFromApi(environment.remunerationList).pipe(take(1)).subscribe((res: any) => {
        this.remunerationList = res;
        this.remunerationListObs.next(res);
      });
    } else if (role == "ROLE_CANDIDATE" && !this.candidateReferencesLoaded) {
      this.candidateReferencesLoaded = true;

    }
  }


  /**
  * Get list (uid-label) from API
  * @param {string} fieldName the fieldName to request
  * @return {BehaviorSubject} searchObs list
  */
  getListFromApi(listUrl: any) {
    console.log('REFERENCESERVICE getListFromApi() requette =');
    console.log(JSON.parse(JSON.stringify(this.transferState)));
    console.log(listUrl);
    const DATA_KEY: any = makeStateKey(listUrl.slice(-(listUrl.length - listUrl.lastIndexOf('api/') - 4)));
    console.log(DATA_KEY);

    if (this.transferState.hasKey(DATA_KEY)) {
      console.log('REFERENCESERVICE getListFromApi() transferState.hasKey');
      console.log(DATA_KEY);
      let data: any = this.transferState.get(DATA_KEY, null);
      return new BehaviorSubject(data);
    } else {
      return this.backApiService.getData(listUrl + '?itemsPerPage=500', false, false).pipe(take(1),
        map((res: any) => {
          console.log('REFERENCESERVICE getListFromApi() res retourned =');
          //  console.error(res);
          console.log(DATA_KEY);
          if (this.windowService.isPlatformServer() && DATA_KEY) {
            this.transferState.set(DATA_KEY, res);
          }
          return res;
        }),
        catchError(e => {
          console.log("REFERENCESERVICE getListFromApi() res returned error");
          this.showAlert("Impossible de se connecter. Verifiez votre connexion ou réessayez plus tard");
          throw e;
        }));
    }
  }


  /**
   * Retrieves a list based on the given field name.
   * 
   * @param fieldName - The name of the field to retrieve the list for.
   * @returns A deep copy of the list associated with the given field name, or null if the field name is not recognized.
   */
  getList(fieldName: string) {
    switch (fieldName) {
      case "skills":
        return JSON.parse(JSON.stringify(this.skillsList));
      case "companyTypes":
        return JSON.parse(JSON.stringify(this.structuresList));
      case "companyTypesPartial":
        return JSON.parse(JSON.stringify(this.structuresListPartial));
      case "qualifications":
        return JSON.parse(JSON.stringify(this.jobsList));
      case "jobs":
        return JSON.parse(JSON.stringify(this.jobsList));
      case "contracts":
        return JSON.parse(JSON.stringify(this.contractsList));
      case "intermediates":
        return JSON.parse(JSON.stringify(this.intermediatesList));
      case "workingTimes":
        return JSON.parse(JSON.stringify(this.workingTimeList));
      case "languages":
        return JSON.parse(JSON.stringify(this.languesList));
      case "languageLevelList":
        return JSON.parse(JSON.stringify(this.languageLevelList));
      case "softwares":
        return JSON.parse(JSON.stringify(this.softwaresList));
      case "experience":
        return JSON.parse(JSON.stringify(this.experienceList));
      case "formations":
        return JSON.parse(JSON.stringify(this.formationsList));
      case "remuneration":
        return JSON.parse(JSON.stringify(this.remunerationList));
      case "advantages":
        return JSON.parse(JSON.stringify(this.advantagesList));
      case "workingSaturday":
        return JSON.parse(JSON.stringify(this.workingSaturdayList));
      case "pda":
        return JSON.parse(JSON.stringify(this.pdaList));
      case "missions":
        return JSON.parse(JSON.stringify(this.missionsList));
      case "environments":
        return JSON.parse(JSON.stringify(this.environmentsList));
      case "specialities":
        return JSON.parse(JSON.stringify(this.specialitiesList));
      case "salary":
        return JSON.parse(JSON.stringify(this.salaryList));
      case "projectTypes":
        return JSON.parse(JSON.stringify(this.associationTypeList));
      case "recruiterProjectTypes":
        return JSON.parse(JSON.stringify(this.recruiterAssociationTypeList));
      case "searchAssociationTypeList":
        return JSON.parse(JSON.stringify(this.searchAssociationTypeList));
      case "recruiterOfferProjectTypes":
        return JSON.parse(JSON.stringify(this.recruiterOfferAssociationTypeList));
      case "salaryTypes":
        return JSON.parse(JSON.stringify(this.salaryTypesList));
      // code...
      default:
        break;
    }
    return null;
  }

  /**
   * Retrieves an observable list based on the provided field name.
   * 
   * @param fieldName - The name of the field to retrieve the list for.
   * @returns An observable list based on the provided field name.
   */
  getListObs(fieldName: string) {
    switch (fieldName) {
      case "jobs":
        return this.jobsListObs.asObservable();
      case "companyTypes":
        return this.structuresListObs.asObservable();
      case "companyTypesPartial":
        return this.structuresListPartialObs.asObservable();
      case "remuneration":
        return this.remunerationListObs.asObservable();
      case "advantages":
        return this.advantagesListObs.asObservable();
      case "softwares":
        return this.softwaresListObs.asObservable();
      case "languages":
        return this.languagesListObs.asObservable();
      case "missions":
        return this.missionsListObs.asObservable();
      case "evalAccuracy":
        return this.evalAccuracyObs.asObservable();
      case "evalContactType":
        return this.evalContactTypeObs.asObservable();
      default:
        break;
    }
    return new BehaviorSubject(null);
  }


  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }


}
