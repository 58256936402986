import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { SlugifyService } from '../../services/slugify/slugify.service';
import { AlertController } from '@ionic/angular';
import { WindowService } from '../../services/window/window.service';

@Injectable({
	providedIn: 'root'
})
/**
 * Service for interacting with the Tomtom API.
 */
export class AlgoliaService {
	tomtomAPIKey: string = '';
	private apiCallCount = 0;
	private resetInterval = 3 * 60 * 1000;
	private maxApiCalls = 50;
	intervalId: any;

	constructor(
		private backApiService: BackApiService,
		private alertController: AlertController,
		private windowService: WindowService,
		private slugifyService: SlugifyService) {
		if (environment.isPwa) {
			this.tomtomAPIKey = environment.tomtomPwaAPIKey;
		} else {
			this.tomtomAPIKey = environment.tomtomAppAPIKey;
		}
		if (this.windowService.isPlatformBrowser()) {
			this.intervalId = setInterval(() => {
				this.apiCallCount = 0;
			}, this.resetInterval);
		}
	}

	private canMakeApiCall(): boolean {
		if (this.windowService.isPlatformServer() || this.apiCallCount < this.maxApiCalls) {
			this.apiCallCount++;
			return true;
		} else {
			this.showAlert('Vous avez atteint la limite de requêtes autorisées. Veuillez réessayer dans quelques minutes', 'Limite de requêtes atteinte');
			return false;
		}
	}

	ngOnDestroy() {
		if (this.intervalId && this.windowService.isPlatformBrowser()) {
			clearInterval(this.intervalId);
		}
	}

	/** 
	* Make a post to TomTom API to get a complete adress from a query
	* @param {string} search the search terms to query
	**/
	getAdress(search: string, addDomsToms: boolean = false) {
		if (!this.canMakeApiCall()) {

			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}
		search = encodeURIComponent(search);
		const url = environment.tomtomPath + '/' + search + '.json' + '?typeahead=true' + '&limit=6' + '&countrySet=fr%2Cbe%2Cad%2Clu%2Cch%2Cnc%2Cpf%2Cyt%2Cpm%2Cbl%2Cmf%2Cwf%2Cre%2Cmq%2Cgp%2Cgy%2Cit%2Ces%2Cmc%2Cgf' +
			'&language=fr-FR' + '&minFuzzyLevel=1' + '&maxFuzzyLevel=2' + '&idxSet=PAD%2CAddr%2CPOI' + '&view=Unified' + '&relatedPois=off' + '&key=' + this.tomtomAPIKey;
		return this.backApiService.getData(url, false).pipe(
			map((res: any) => {
				console.log("ALGOLIASERVICE formatTomtomToAlgolia() request to TomTom =");
				console.log(res);
				let formatedRes = [];
				if (res && res['results'] && res['results'].length > 6) {
					formatedRes = res['results'].splice(0, 6);
					formatedRes = this.formatTomtomToAlgolia(formatedRes);
				} else if (res && res['results'][0]) {
					formatedRes = res['results'];
					formatedRes = this.formatTomtomToAlgolia(formatedRes);
				} else {
					formatedRes = [{ "locale_names": "Aucune adresse trouvée" }];
				}
				console.log("ALGOLIASERVICE formatTomtomToAlgolia() before return");
				console.log(res);
				if (addDomsToms) {
					this.addDomsToms(formatedRes, search);
				}
				return formatedRes;
			}), catchError(e => {
				console.log("TOMTOM getAdress() returned error");
				throw e;
			}));
	}


	/**
	 * Calculates the Levenshtein distance between two strings.
	 * The Levenshtein distance is a measure of the difference between two strings,
	 * defined as the minimum number of single-character edits (insertions, deletions, or substitutions)
	 * required to change one string into the other.
	 *
	 * @param a - The first string.
	 * @param b - The second string.
	 * @returns The Levenshtein distance between the two strings.
	 */
	levenshtein(a: string, b: string): number {
		const matrix: number[][] = [];
		b = b.slice(0, a.length);

		for (let i = 0; i <= b.length; i++) {
			matrix[i] = [i];
		}

		for (let j = 0; j <= a.length; j++) {
			matrix[0][j] = j;
		}

		for (let i = 1; i <= b.length; i++) {
			for (let j = 1; j <= a.length; j++) {
				if (b.charAt(i - 1) === a.charAt(j - 1)) {
					matrix[i][j] = matrix[i - 1][j - 1];
				} else {
					matrix[i][j] = Math.min(
						matrix[i - 1][j - 1] + 1,
						Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
					);
				}
			}
		}

		return matrix[b.length][a.length];
	}

	/**
	 * Check if the search term matches a DOM-TOM
	 * @param searchTherm 
	 * @returns 
	 */
	searchThermMatchDomTom(searchTherm: string): boolean {
		console.log("ALGOLIASERVICE searchThermMatchDomTom()");
		const domtoms = [
			'La Réunion',
			'Guadeloupe',
			'Martinique',
			'Mayotte',
			'Guyane',
			'Saint-Pierre-et-Miquelon',
			'St-Pierre-et-Miquelon',
			'Saint-Barthélemy',
			'St-Barthélemy',
			'Saint-Martin',
			'St-Martin',
			'Wallis-et-Futuna',
			'Nouvelle-Calédonie',
			'Polynésie'
		];

		const slugifiedSearchTerm = this.slugifyService.slugify(decodeURIComponent(searchTherm));

		return domtoms.some(domtom => {
			const slugifiedDomTom = this.slugifyService.slugify(domtom);
			console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedDomTom = " + slugifiedDomTom);
			console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedSearchTerm = " + slugifiedSearchTerm);
			console.log(searchTherm);
			if (slugifiedSearchTerm.length > 3) {
				console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedSearchTerm.length > 3");
				const distance = this.levenshtein(slugifiedSearchTerm, slugifiedDomTom);
				return distance <= 1; // Autoriser une faute de frappe
			} else if (slugifiedSearchTerm.length > 6) {
				console.log("ALGOLIASERVICE searchThermMatchDomTom() slugifiedSearchTerm.length > 6");
				const distance = this.levenshtein(slugifiedSearchTerm, slugifiedDomTom);
				return distance <= 2; // Autoriser 2 fautes de frappe
			} else {
				console.log("ALGOLIASERVICE searchThermMatchDomTom() else");
				return slugifiedSearchTerm.startsWith(slugifiedDomTom);
			}
		});
	}

	/**
	 * Add DOM-TOMs to the list of results
	 * @param formatedRes 
	 * @param search
	 * @returns
	 * */
	addDomsToms(formatedRes: any, searchTherm: string = '') {
		console.log("ALGOLIASERVICE addDomsToms()");
		console.log(formatedRes);
		let domtomFound: boolean = false;
		formatedRes.forEach((element: any) => {
			if (
				element?.countryCode == 'RE' || // La Réunion
				element?.countryCode == 'GP' || // Guadeloupe
				element?.countryCode == 'MQ' || // Martinique
				element?.countryCode == 'YT' || // Mayotte
				element?.countryCode == 'GF' || // Guyane française
				element?.countryCode == 'PM' || // Saint-Pierre-et-Miquelon
				element?.countryCode == 'BL' || // Saint-Barthélemy
				element?.countryCode == 'MF' || // Saint-Martin (partie française)
				element?.countryCode == 'WF' || // Wallis-et-Futuna
				element?.countryCode == 'TF' || // Terres australes et antarctiques françaises
				element?.countryCode == 'NC' || // Nouvelle-Calédonie
				element?.countryCode == 'PF'    // Polynésie française
			) {
				domtomFound = true;
			}
		});
		if (domtomFound || this.searchThermMatchDomTom(searchTherm)) {
			formatedRes.unshift({
				"locale_names": "France d'outre-mer",
				"countrySecondarySubdivision": "Tous les DOM-TOMs",
			});
			console.log("ALGOLIASERVICE addDomsToms() end");
			console.log(formatedRes);
		}
	}

	/**
	 * Format the response from Tomtom API to Algolia format
	 * @param res 
	 * @returns 
	 */
	formatTomtomToAlgolia(res: any) {
		console.log("ALGOLIASERVICE formatTomtomToAlgolia()");
		console.log(res);
		if (res[0]) {
			for (let i = 0; i < res.length; i++) {
				if (res[i].position.lat) {
					res[i]['_geoloc'] = {};
					res[i]['_geoloc'].lat = res[i].position.lat;
					res[i]['_geoloc'].lng = res[i].position.lon;
					res[i].address['_geoloc'] = res[i]['_geoloc'];
				}
				res[i] = res[i].address;
				if (res[i].freeformAddress) {
					res[i].locale_names = res[i].freeformAddress;
				}
				if (res[i].countrySubdivision) {
					res[i].locality = res[i].countrySubdivision;
				}
				if (res[i].countrySecondarySubdivision) {
					res[i].departement = res[i].countrySecondarySubdivision;
				}
				if (res[i].postalCode) {
					res[i].zipcode = res[i].postalCode;
					res[i].postcode = res[i].postalCode;
				}
				if (res[i].municipality) {
					res[i].city = res[i].municipality;
					res[i].citySlug = this.slugifyService.slugify(res[i].municipality.toLowerCase());
				}
				if (res[i].streetName) {
					res[i].street = res[i].streetName;
				}
				if (res[i].streetNumber) {
					res[i].number = res[i].streetNumber;
				}
				if (res[i].streetName && res[i].streetNumber) {
					res[i].formatedAddress = res[i].streetNumber + ' ' + res[i].streetName + ', ' + res[i].postcode + ' ' + res[i].city;;
				}
				else {
					res[i].formatedAddress = res[i].freeformAddress;
				}
				if (res[i]['_geoloc'].lat) {
					res[i].latitude = res[i]['_geoloc'].lat;
					res[i].longitude = res[i]['_geoloc'].lng;
				}
			}
		}
		console.log("ALGOLIASERVICE formatTomtomToAlgolia() end");
		console.log(res);
		return res;
	}

	/** 
	* Make a post to Algolia API to get a city  from a query
	* @param {string} search the search terms to query
	**/
	getCity(search: string, addDomsToms: boolean = false) {
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}
		search = encodeURIComponent(search);
		const url = environment.tomtomPath + '/' + search + '.json' + '?typeahead=true' + '&limit=6' + '&countrySet=fr%2Cbe%2Cad%2Clu%2Cch%2Cnc%2Cpf%2Cyt%2Cpm%2Cbl%2Cmf%2Cwf%2Cre%2Cmq%2Cgp%2Cgy%2Cit%2Ces%2Cmc%2Cgf' +
			'&language=fr-FR' + '&minFuzzyLevel=1' + '&maxFuzzyLevel=2' + '&idxSet=Geo' + '&entityTypeSet=Municipality,PostalCodeArea,MunicipalitySubdivision' + '&view=Unified' + '&relatedPois=off' + '&key=' + this.tomtomAPIKey;
		return this.backApiService.getData(url, false).pipe(
			map((res: any) => {
				console.log("ALGOLIASERVICE formatTomtomToAlgolia() request to TomTom =");
				console.log(res);
				let formatedRes = [];
				if (res && res['results'] && res['results'].length > 6) {
					formatedRes = res['results'].splice(0, 6);
					formatedRes = this.formatTomtomToAlgolia(formatedRes);
				} else if (res && res['results'][0]) {
					formatedRes = res['results'];
					formatedRes = this.formatTomtomToAlgolia(formatedRes);
				} else {
					formatedRes = [{ "locale_names": "Aucune adresse trouvée" }];
				}
				console.log("ALGOLIASERVICE formatTomtomToAlgolia() before return");
				if (addDomsToms) {
					this.addDomsToms(formatedRes, search);
				}
				return formatedRes;
			}), catchError(e => {
				console.log("TOMTOM getAdress() returned error");
				throw e;
			}));
	}


	/**
	 * Get the postcode from coordinates
	 * @param coordinates 
	 * @returns 
	 */
	getPostcode(coordinates: any) {
		console.log("ALGOLIASERVICE getPostcode() start");
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}
		const url = environment.tomtomReverse + '/' + coordinates + '.json' + '?returnSpeedLimit=false' +
			'&language=fr-FR' + '&entityTypeSet=PostalCodeArea' + '&view=Unified' + '&radius=10000' + '&returnRoadUse=false' + '&allowFreeformNewLine=false' + '&returnMatchType=false' + '&key=' + this.tomtomAPIKey;
		return this.backApiService.getData(url, false).pipe(
			map((res: any) => {
				console.log("ALGOLIASERVICE getPostcode() request to TomTom =");
				console.log(res);
				let cp = '';
				if (res.addresses[0]?.address?.postalCode) {
					cp = res.addresses[0]?.address?.postalCode;
				} else if (res.addresses[0]?.address?.countryCode == 'PF') {
					cp = '98714';
				}
				return cp;
			}), catchError(e => {
				console.log("TOMTOM getPostcode() returned error");
				throw e;
			}));
	}

	/**
	 * Get the current position lat lng of the user
	 * @returns {Observable<any>} an observable of the current position
	 */
	getCurrentLatLng(): Observable<any> {
		let latLng;
		let lat: string;
		let lng: string;
		let observableFromPromise;
		return observableFromPromise =
			from(
				Geolocation.getCurrentPosition().then((resGeo) => {
					lat = resGeo.coords.latitude.toString();
					lng = resGeo.coords.longitude.toString();
					console.log('ALGOLIASERVICE geolocalise()');
					console.log(resGeo);
					latLng = { lat: lat, lng: lng };
					return latLng;
				}, e => {
					console.log('Error getting location', e);
					throw e
				}));
	}

	/**
	 * get the city from coordinates
	 * @param coordinates 
	 * @returns 
	 */
	getCityFromCoordinates(coordinates: any) {
		console.log("ALGOLIASERVICE getCityFromCoordinates() start");
		if (!this.canMakeApiCall()) {
			return throwError(() => new Error('Rate limit exceeded. Please try again later.'));
		}
		const url = environment.tomtomReverse + '/' + coordinates + '.json' + '?returnSpeedLimit=false' +
			'&language=fr-FR' + '&entityTypeSet=PostalCodeArea' + '&view=Unified' + '&radius=10000' + '&returnRoadUse=false' + '&allowFreeformNewLine=false' + '&returnMatchType=false' + '&key=' + this.tomtomAPIKey;
		return this.backApiService.getData(url, false).pipe(
			tap((res: any) => {
				console.log("ALGOLIASERVICE getCityFromCoordinates() request to TomTom =");
				console.log(res);
				return res;
			}), catchError(e => {
				console.log("TOMTOM getCityFromCoordinates() returned error");
				throw e;
			}));
	}

	/**
	* Display Error
	* @param {string} msg Error message
	*/
	showAlert(msg: string = "", title: string = "Erreur") {
		let alert = this.alertController.create({
			message: msg,
			header: title,
			buttons: ['OK']
		});
		alert.then(alert => alert.present());
	}
}



