import { Injectable, NgZone } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { AlertController, LoadingController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from "src/environments/environment";
import { PlatformService } from '../platform/platform.service';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { BackApiService } from '../../services/back-api/back-api.service';
import { map, tap, catchError } from 'rxjs/operators';
import { WindowService } from '../../services/window/window.service';
import { DataLayerService } from '../../services/data-layer/data-layer.service';
import { App } from '@capacitor/app';




@Injectable({
	providedIn: 'root'
})
export class SsoService {
	authConfig: AuthConfig = {};
	isIosPlatform: boolean = false;

	constructor(
		private authService: AuthService,
		private backApiService: BackApiService,
		private oauthService: OAuthService,
		private alertController: AlertController,
		public loadingController: LoadingController,
		private windowService: WindowService,
		private dataLayerService: DataLayerService,
		private platformService: PlatformService,
		private ngZone: NgZone
	) {
		if (this.platformService.is('ios')) {
			this.isIosPlatform = true;
		}
		console.log("SSOINIT")
	}


	/**
	* Log/register user to Google, ask permission and return token to be validated by the backend
	*/
	doGoogleLogin() {
		let googleClientId = environment.googleClientIdWeb;
		if (this.platformService.is('ios')) {
			googleClientId = environment.googleClientIdIos;
		} else if (this.platformService.is('android')) {
			googleClientId = environment.googleClientIdWeb;
			console.log('GOOGLE paltform is android');
			console.log(googleClientId);
		}
		return new Promise((resolve, reject) => {
			FirebaseAuthentication.removeAllListeners().then(() => {
				console.log("removeAllListeners");
				console.log("authStateChange");
				FirebaseAuthentication.signInWithGoogle().then(result => {
					console.log("Auth end 3");
					console.log(result);
					resolve(result.credential);
				}), (error: any) => {
					console.error("GOOGLE Failed to sign in", error);
					reject(error);
				};
			});
		});
	}


	/**
	* Log/register user to Apple, ask permission and return token to be validated by the backend
	*/
	doAppleLogin() {
		console.log("SSO_S doAppleLogin(à start");
		return new Promise((resolve, reject) => {
			FirebaseAuthentication.removeAllListeners().then(() => {
				console.log("removeAllListeners");
				console.log("authStateChange");
				FirebaseAuthentication.signInWithApple({ scopes: ['name', 'email'] }).then(result => {
					console.log("Auth end 3");
					console.log(result);
					resolve(result);
				}), (error: any) => {
					console.error("GOOGLE Failed to sign in", error);
					reject(error);
				};
				//	});
			});

		});
	}



	/**
	 * Retrieves an access token from the SSO token.
	 * 
	 * @param ssoProvider - The SSO provider.
	 * @param ssoRes - The SSO response.
	 * @param role - The role (default: 'ROLE_CANDIDATE').
	 * @returns An Observable that emits the response from the API call.
	 */
	getTokenFromSSOToken(ssoProvider: string, ssoRes: any, role: string = 'ROLE_CANDIDATE') {
		console.log('SSO SERVICE getTokenFromSSOToken()');
		console.log(ssoProvider);
		console.log(ssoRes);
		console.log(role);
		if (ssoRes.state == "banane") {	// REPLACE BANANE BY THE STATE STORED TO BE STORED IN MEMORY 
			//	return;
		}
		let from = '';
		if (environment.isPwa) {
			from = 'web';
		} else if (this.platformService.is('ios')) {
			from = 'app_ios';
		} else if (this.platformService.is('android')) {
			from = 'app_android';
		}
		let url = "";
		let body: any = {};
		switch (ssoProvider) {
			case "linkedin":
				url = environment.linkedinSso;
				body = {
					"code": ssoRes.code,
					"state": ssoRes.state,
					"redirectUrl": environment.websiteUrl + '/sso-linkedin',
					"role": role,
					"from": from
				}
				/* PWA */
				if (environment.isPwa) {
					body.redirectUrl = environment.linkedinRedirectUri;
					delete body.from;
					delete body.state;
				}
				break;
			case "google":
				url = environment.googleSso;
				body = {
					"idToken": ssoRes.idToken,
					"role": role,
					//	"from": from
				}
				/* PWA */
				if (environment.isPwa) {
					body.idToken = ssoRes.encodedToken.credential;
					url += "/web";
				} else if (this.platformService.is('android')) {
					url += "/web";
				} else if (this.platformService.is('ios')) {
					url += "/ios";
				}
				break;
			case "apple":
				url = environment.appleSso;
				body = {
					"code": ssoRes.authorizationCode,  // CHANGE HERE WHEN API READY
					//  "idToken": ssoRes.identityToken,
					"role": role,
					"from": from
				}
				/* PWA */
				if (environment.isPwa) {
					body.code = ssoRes.code;
					body.redirectUri = environment.appleRedirectUri;
					delete body.from;
				}
				break;

			default:
				// code...
				break;
		}
		console.log('Before calling API : getTokenFromSSOToken');
		console.log(body);
		return this.backApiService.postData(`${url}`, body, false, false).pipe(
			//     return this.authService.login(body).pipe(
			tap((res: any) => {
				console.log('SSOService - getTokenFromSSOToken() : res =');
				console.log(res);
				let createTokenResponse = null;
				if (res.createTokenResponse) {
					createTokenResponse = res.createTokenResponse;
				} else {
					createTokenResponse = JSON.parse(res).createTokenResponse;
				}
				if (this.windowService.isPlatformBrowser() && res.newAccount) {
					this.dataLayerService.logEvent('account_creation', { type: body.role });
				}
				this.authService.setAuthAndTokenInfoAfterSso(createTokenResponse);
				return res;
			}),
			catchError(e => {
				console.log("SSOSERVICE doLinkedInLogin() res returned error");
				this.showAlert("Erreur", "Impossible se connecter. Verifiez votre connexion ou essayez de réinitialiser votre mot de passe via le boutton s'identifier avec un email");
				throw e;
			}));

	}


	/**
	 * Performs an OAuth OIDC login for the specified SSO provider.
	 * 
	 * @param ssoProvider The SSO provider to use for the login (default: 'poleEmploi').
	 * @returns A promise that resolves with the login response.
	 */
	angularOAuthOidcLogin(ssoProvider: string = 'poleEmploi'): Promise<any> {
		console.log("SSO_S angularOAuthOidcLogin() pwa = " + environment.isPwa);
		return this.oauthService.createAndSaveNonce().then((nonce: any) => {
			let state: string = Math.floor(Math.random() * 1000000000).toString();
			if (this.windowService.getCrypto()) {
				const array = new Uint32Array(1);
				this.windowService.getCrypto().getRandomValues(array);
				state = array.join().toString();
			}
			return new Promise((resolve, reject) => {
				const oauthUrl = this.buildOAuthUrl(state, nonce, ssoProvider);
				console.log('url =');
				console.log(oauthUrl);
				let browser: any;
				if (!oauthUrl) {
					return;
				}
				if (!environment.isPwa) {	// Linkedin
					if (this.platformService.is('android')) {
						this.windowService.open(oauthUrl, '_blank', 'location=no,clearsessioncache=yes,clearcache=yes');
					} else {
						browser = Browser.open({ url: oauthUrl, windowName: '_blank' });	// ,'location=no,clearsessioncache=yes,clearcache=yes'
					}
					console.log('ABAAAAAAAAAA BBBBCCDDEEF SSO_S angularOAuthOidcLogin() before event subscribe ');
					App.addListener('appUrlOpen', (url: any) => {
						console.log("AAAAAAAAAAAAAAAAA  ******* SSO_S angularOAuthOidcLogin() appUrlOpen = ");
						console.log(url);
						// Must run inside an NgZone for Angular to pick up the changes
						// https://capacitorjs.com/docs/guides/angular
						this.ngZone.run(() => {
							console.log("SSO_S angularOAuthOidcLogin() ngZone = ");
							console.log(url);
							const responseParameters = (url.url.replace('?', '&')).split('&');
							console.log(responseParameters);
							let parsedResponse: any = {};
							for (let i = 0; i < responseParameters.length; i++) {
								parsedResponse[responseParameters[i].split('=')[0]] =
									responseParameters[i].split('=')[1];
							}
							console.log('SETTINGS checkSsoInUrlAndLogin() parsed response =');
							console.log(parsedResponse);
							if (this.platformService.is('ios')) {
								Browser.close().then(() => { });
							}
							resolve(parsedResponse);
						});
					});
				} else {
					if (ssoProvider == 'linkedin') {
						console.log("SSO_S angularOAuthOidcLogin() case linkedin !");
						// browser = this.windowService.open(oauthUrl, '_self',
						// 	'location=no,clearsessioncache=yes,clearcache=yes');	// location=no,clearsessioncache=yes,clearcache=yes, 
						Browser.open({ url: oauthUrl, windowName: '_self' });
					} else if (ssoProvider == 'apple') {
						browser = this.windowService.open(oauthUrl, '_self');
						this.windowService.getMessagesEvents().subscribe(event => {
							console.log("SSO APPLE MESSAGE RECEIVED");
							console.log(event);
						});
					} else if (ssoProvider == 'linkedin') {
						browser = this.windowService.open(oauthUrl, '_self',
							'location=no,clearsessioncache=yes,clearcache=yes');	// location=no,clearsessioncache=yes,clearcache=yes, 

					}
				}

			});

		});
	}



	/**
	 * Builds the OAuth URL for the specified SSO provider.
	 * 
	 * @param state - The state parameter for the OAuth URL.
	 * @param nonce - The nonce parameter for the OAuth URL.
	 * @param ssoProvider - The SSO provider ('linkedin' or 'apple').
	 * @returns The OAuth URL as a string or null if the SSO provider is not supported.
	 */
	buildOAuthUrl(state: string, nonce: string, ssoProvider: string): string | null {
		if (ssoProvider == 'linkedin') {
			this.authConfig = {
				issuer: 'https://www.linkedin.com/oauth/v2',
				redirectUri: environment.websiteUrl + '/sso-linkedin',
				clientId: environment.linkedinClientId,
				scope: "r_emailaddress r_liteprofile",
				showDebugInformation: false
			};
			if (environment.isPwa) {
				this.authConfig.redirectUri = this.windowService.getLocationOrigin() + '/sso-linkedin';
			}
			this.oauthService.configure(this.authConfig);
			return this.oauthService.issuer + '/authorization?' +
				'client_id=' + this.oauthService.clientId + '&' +
				'response_type=code&' +
				'scope=' + encodeURI(this.oauthService.scope as string) + '&' +
				'state=' + state + '&nonce=' + nonce + '&' +
				'redirect_uri=' + this.oauthService.redirectUri;
		} else if (ssoProvider == 'apple') {	// https://appleid.apple.com/auth/authorize?response_type=code&client_id=fatmap.com&redirect_uri=https%3A%2F%2Ffir-prod-1e573.firebaseapp.com%2F__%2Fauth%2Fhandler&state=AMbdmDlyEAszYpE3TkjHSxFGVvbf6OU9EbbJWfLHhGgjFIE7PBMCZSpGXFIp-tt24LGz-52a_vE8Nara48-jXgWUyaWz-zcVBBdAf5G1C-v0FP135vuFetzNU5eMwNktoefaoD2V_sleCilDrWaVIa0QV31LQ6yaGLRwMKJsU6oeq4Zr5fSOje3R3xb97y3ZKxYoCqCmY4jowN6A4_TiKKXNHPIxcr08v2lqD64NAjjWrHnblqtCjvK4nvNIJ0KfIvgazvUxwB6Cx2kkGZFIYgGS_StQermn4ad0BKQJz_JAFf1vaS_PTo2HP1W7ICoI9Ez3YOsdOg&scope=email%20name&response_mode=form_post&locale=en&context_uri=https%3A%2F%2Ffatmap.com
			this.authConfig = {
				issuer: 'https://appleid.apple.com/auth/authorize',
				redirectUri: environment.websiteUrl + '/sso-apple',	// TO BE UPDATED
				clientId: environment.appleClientId,
				responseType: "code id_token",
				scope: "name email",
				showDebugInformation: false
			};
			this.oauthService.configure(this.authConfig);
			return this.oauthService.issuer + '?' +
				'client_id=' + this.oauthService.clientId + '&' +
				'response_type=code&' +
				'scope=' + encodeURI(this.oauthService.scope as string) + '&' +
				'state=' + state + '&nonce=' + nonce + '&' +
				'redirect_uri=' + this.oauthService.redirectUri + '&' +
				'response_mode=form_post&locale=fr&context_uri=' + environment.websiteUrl;
		} else {
			return null;
		}
	}



	/*** showAlert
	@param {string} title title of the popup
	@param {string} msg msg to display
	*/
	async showAlert(title: string = '', msg: string = "") {
		const alert = await this.alertController.create({
			header: title,
			message: msg,
			buttons: ["OK"]
		});

		await alert.present();
	}





}





