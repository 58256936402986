import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { BackApiService } from '../../services/back-api/back-api.service';
import { tap } from 'rxjs/operators';
import { filter, map } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DataLayerService } from '../data-layer/data-layer.service';
import { WindowService } from '../window/window.service';
import { PlatformService } from '../platform/platform.service';
import { SsoService } from '../../services/sso/sso.service';

type AppMode = 'transaction-offline' | 'transaction' | 'recruiter' | 'candidate' | 'job-offline';

export interface BreadcrumbItem {
	label: string;
	path?: string;
}

@Injectable({
	providedIn: 'root'
})

/**
* Used to have network state and the datetime of last meal updated on server
**/
export class SettingsService {
	ssoApple: any = new BehaviorSubject(false);	// false -> connected -> completed
	subscribtions: Array<Subscription> = [];
	outdatedVersion: any = new BehaviorSubject(false);
	shouldRate = new BehaviorSubject(false);
	rateEvents = { rated: false, nbRateEvent: 0, nbRateRequests: 0, lastRateRequest: new Date('December 17, 1995 00:00:00') };
	breadcrumbsObs: BehaviorSubject<any> = new BehaviorSubject(null);
	isMobile: boolean = true;
	isMobileObs = new BehaviorSubject(true);
	displayTabsObs = new BehaviorSubject(false);
	userRole: string | null = null;
	userRoleObs: BehaviorSubject<any> = new BehaviorSubject(null);
	roleLoaded: boolean = false;
	ssoLoginObs: BehaviorSubject<any> = new BehaviorSubject(null);
	hasDefinedIsMobile: boolean = false;
	pwaSsoRole: string | null = null;
	activePage: string | null = null;
	fromCampaign: string | null = null;
	appModeObs: BehaviorSubject<any> = new BehaviorSubject(null);
	activePageObs: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(
		private authService: AuthService,
		private storage: LocalStorageService,
		private backApiService: BackApiService,
		private router: Router,
		private ssoService: SsoService,
		private activatedRoute: ActivatedRoute,
		private windowService: WindowService,
		private platformService: PlatformService,
		private dataLayerService: DataLayerService,
	) {
		console.log('SETTINGS Constructor');
		this.checkIsMobile();
		this.checkTabsDisplay();
		this.detectAppMode();
		this.initBreadCrumpAndTrackPages();
		this.storage.get('rateEvents').then((res: any) => {
			if (res?.lastRateRequest) {
				this.rateEvents = res;
			}
		});
	}

	/**
	 * Checks the display of tabs based on the device's screen size and user authentication status.
	 * 
	 * @remarks
	 * This method subscribes to the `isMobile` observable to determine if the device is a mobile device.
	 * If the user is authenticated and the device is a mobile device, the `displayTabsObs` subject is updated with `true`.
	 * Otherwise, the `displayTabsObs` subject is updated with `false`.
	 * 
	 * @returns void
	 */
	checkTabsDisplay() {
		let isMobileSubs = this.getIsMobile().subscribe(isMobile => {
			this.hasDefinedIsMobile = true;
			console.log("SETTINGS displayTabs isMobile =");
			console.log(isMobile);
			let authSubs = this.authService.isAuthenticatedObs().subscribe(isAuthenticated => {
				if (isAuthenticated && isMobile) {
					console.log("SETTINGS displayTabs = true");
					this.displayTabsObs.next(true);
				} else {
					this.displayTabsObs.next(false);
					console.log("SETTINGS displayTabs = false");
				}
			});
			this.addSubscribtion(authSubs);
		});
		this.addSubscribtion(isMobileSubs);
	}

	/**
 * Get observable for displayTabs
 * @return {BehaviorSubject<boolean>} Observable for displayTabs
 */
	getDisplayTabs() {
		return this.displayTabsObs;
	}

	/**
	 * Checks if the current window width is within the mobile range (less than 992 pixels).
	 * Updates the `isMobile` property and emits the new value through the `isMobileObs` subject.
	 * Subscribes to window resize events to keep track of changes in window width.
	 * @remarks
	 * This method relies on the `windowService` to get the current window width.
	 * @returns void
	 */
	checkIsMobile() {
		console.log("SETTINGS checkIsMobile()");
		console.log(this.windowService.getWidth());
		if (this.windowService.getWidth() > 0 && this.windowService.getWidth() < 992) {
			this.isMobile = true;
			this.isMobileObs.next(true);
		} else {
			this.isMobile = false;
			this.isMobileObs.next(false);
		}
		let resizeSubs = this.windowService.getResizeEvents().subscribe(evt => {
			console.log("SETTINGS checkIsMobile() resize");
			let width = this.windowService.getWidth();
			console.log(width);
			console.log("Width changed");
			console.log(width);
			if (this.windowService.getWidth() > 0 && width < 992 && !this.isMobile) {
				this.isMobile = true;
				this.isMobileObs.next(true);
				console.log("SETTINGS checkIsMobile() isMobile changed");
			} else if (width >= 992 && this.isMobile) {
				this.isMobile = false;
				this.isMobileObs.next(false);
				console.log("SETTINGS checkIsMobile()  isMobile changed");
			}
		});
		this.addSubscribtion(resizeSubs);
	}

	/**
 * Get observable for isMobile status
 * @return {BehaviorSubject<boolean>} Observable for isMobile status
 */
	getIsMobile() {
		return this.isMobileObs;
	}

	/**
 * Get the currently active page
 * @return {string | null} Active page URL
 */
	getActivePage() {
		return this.activePage;
	}

	/**
	 * Checks if the Single Sign-On (SSO) is present in the URL and performs the login process.
	 * This method listens to the router events and handles the SSO login for different providers such as LinkedIn, Apple, Google, and Facebook.
	 * It retrieves the SSO token from the URL, parses the response parameters, and sends a request to the SSO service to get the token for the specified provider.
	 * If the login is successful, it emits a value to the ssoLoginObs subject.
	 */
	checkSsoInUrlAndLogin() {
		let routerEventSubs = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(async () => {
				this.activePage = this.router.url;
				this.activePageObs.next(this.router.url);
				if (this.windowService.isPlatformServer()) {
					return;
				}
				console.log("SETTINGS checkSsoInUrlAndLogin() before storage");
				this.pwaSsoRole = await this.storage.get('pwaSsoRole');
				console.log("SETTINGS checkSsoInUrlAndLogin() after storage " + this.pwaSsoRole);
				if (this.router.url.includes('sso')) {
					let role = 'ROLE_CANDIDATE';
					if (this.router.url.includes('-recruteur') || this.pwaSsoRole == 'ROLE_RECRUITER') {	// First part -recruiter might be used by the app. To be checked when I have time
						role = 'ROLE_RECRUITER';
					}
					const responseParameters = (this.router.url.replace('?', '&')).split('&');
					let parsedResponse: any = {};
					for (let i = 0; i < responseParameters.length; i++) {
						parsedResponse[responseParameters[i].split('=')[0]] =
							responseParameters[i].split('=')[1];
					}
					console.log('SETTINGS checkSsoInUrlAndLogin() parsed response =');
					console.log(parsedResponse);
					if (this.router.url.includes('sso-linkedin')) {
						if (environment.isPwa && parsedResponse.redirectUrl) {
							parsedResponse.redirectUrl = this.windowService.getLocationOrigin();
						}
						console.log("SETTINGS checkSsoInUrlAndLogin()  LINKEDIN SSO param");
						console.log(this.router.url);
						let tokenFromSsoSubs = this.ssoService.getTokenFromSSOToken("linkedin", parsedResponse, role).subscribe((res: any) => {
							console.log("SETTINGS checkSsoInUrlAndLogin()  LINKEDIN SSO res = ");
							console.log(res);
							if (res) {
								this.ssoLoginObs.next(true);
							}
						});
						this.addSubscribtion(tokenFromSsoSubs);
					} else if (this.router.url.includes('sso-apple')) {
						if (environment.isPwa && parsedResponse.redirectUrl) {
							parsedResponse.redirectUrl = this.windowService.getLocationOrigin();
						}
						console.log("SETTINGS checkSsoInUrlAndLogin()  APPLE SSO param");
						console.log(this.router.url);
						console.log(parsedResponse);
						let tokenFromSsoSubs = this.ssoService.getTokenFromSSOToken("apple", parsedResponse, role).subscribe((res: any) => {
							console.log("SETTINGS checkSsoInUrlAndLogin()  APPLE SSO res = ");
							console.log(res);
							if (res) {
								this.ssoLoginObs.next(true);
							}
						});
						this.addSubscribtion(tokenFromSsoSubs);
					} else if (this.router.url.includes('sso-google')) {
						if (environment.isPwa && parsedResponse.redirectUrl) {
							parsedResponse.redirectUrl = this.windowService.getLocationOrigin();
						}
						console.log("SETTINGS checkSsoInUrlAndLogin()  GOOGLE SSO param");
						console.log(this.router.url);
						console.log(parsedResponse);
						let tokenFromSsoSubs = this.ssoService.getTokenFromSSOToken("google", parsedResponse, role).subscribe((res: any) => {
							console.log("SETTINGS checkSsoInUrlAndLogin()  GOOGLE SSO res = ");
							console.log(res);
							if (res) {
								this.ssoLoginObs.next(true);
							}
						});
						this.addSubscribtion(tokenFromSsoSubs);
					} else if (this.router.url.includes('sso-facebook')) {
						if (environment.isPwa && parsedResponse.redirectUrl) {
							parsedResponse.redirectUrl = this.windowService.getLocationOrigin();
						}
						console.log("SETTINGS checkSsoInUrlAndLogin()  FACEBOOK SSO param");
						console.log(this.router.url);
						console.log(parsedResponse);
						let tokenFromSsoSubs = this.ssoService.getTokenFromSSOToken("facebook", parsedResponse, role).subscribe((res: any) => {
							console.log("SETTINGS checkSsoInUrlAndLogin()  FACEBOOK SSO res = ");
							console.log(res);
							if (res) {
								this.ssoLoginObs.next(true);
							}
						});
						this.addSubscribtion(tokenFromSsoSubs);
					}
				}
			})).subscribe();
		this.addSubscribtion(routerEventSubs);
	}

	/**
	 * Set fromCampaign from URL
	 * @param {string} url - The URL to extract campaign information from
	 */
	setFromCampaign(url: string) {
		if (url.includes('utm_source=') || url.includes('utm_campaign=') || url.includes('/comparateur') || url.includes('/simulateur')) {
			const responseParameters = (url.replace('?', '&')).split('&');
			console.log('SETTINGS setFromCampaign() responseParameters');
			console.log(responseParameters);
			let parsedResponse: any = {};
			for (let i = 0; i < responseParameters.length; i++) {
				parsedResponse[responseParameters[i].split('=')[0]] =
					responseParameters[i].split('=')[1];
			}
			if (parsedResponse?.utm_source && parsedResponse?.utm_campaign && parsedResponse?.utm_source !== parsedResponse?.utm_campaign) {
				this.fromCampaign = parsedResponse.utm_source + ' - ' + parsedResponse.utm_campaign;
			} else if (parsedResponse.utm_source) {
				this.fromCampaign = parsedResponse.utm_source;
			} else if (parsedResponse.utm_campaign) {
				this.fromCampaign = parsedResponse.utm_campaign;
			} else if (url.includes('/comparateur')) {
				this.fromCampaign = 'comparateur';
			} else if (url.includes('/simulateur')) {
				this.fromCampaign = 'simulateur';
			}
		}
	}

	/**
	 * Get fromCampaign if set
	 * @return {string | null} The campaign information
	 */
	getFromCampaign() {
		return this.fromCampaign;
	}


	/**
	 * Save navigation history to build breadcrumb
	 */
	initBreadCrumpAndTrackPages() {
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map((res: any) => {
				console.log('SETTINGS buildBreadCrumb() dataLayerService');
				console.log(res.url);
				console.log(this.userRole);
				this.setFromCampaign(res.url);
				if (this.roleLoaded) {
					this.dataLayerService.logPageView(res.url, this.userRole);
					console.log('SETTINGS buildBreadCrumb() dataLayerService 1');
				} else {
					if (this.windowService.isPlatformBrowser()) {
						setTimeout(() => {
							this.roleLoaded = true;
							this.dataLayerService.logPageView(res.url, this.userRole);
							console.log('SETTINGS buildBreadCrumb() dataLayerService 2');
						}, 500);
					} else {
						this.dataLayerService.logPageView(res.url, this.userRole);
						console.log('SETTINGS buildBreadCrumb() dataLayerService 3');
					}
				}
				if (environment.isPwa) {
					this.breadcrumbsObs.next(this.buildBreadCrumb(this.activatedRoute.root));
					console.log('SETTINGS buildBreadCrumb()');
					console.log(this.breadcrumbsObs.value);
				}
			})).subscribe();
	}

	/**
	 * Tracks a modal page view using the DataLayerService.
	 * 
	 * @param pageTitle - The title of the page.
	 */
	trackModalPage(pageTitle: any) {
		if (this.roleLoaded) {
			this.dataLayerService.logPageView(pageTitle, this.userRole);
		} else if (this.windowService.isPlatformBrowser()) {
			setTimeout(() => {
				this.roleLoaded = true;
				this.dataLayerService.logPageView(pageTitle, this.userRole);
			}, 500);
		}
	}

	/**
 * Get observable for breadcrumbs
 * @return {BehaviorSubject<BreadcrumbItem[]>} Observable for breadcrumbs
 */
	getBreadcrumbsObs() {
		return this.breadcrumbsObs;
	}


	/**
	 * Builds the breadcrumb items for a given route.
	 * 
	 * @param route - The ActivatedRoute representing the current route.
	 * @param url - The current URL.
	 * @param breadcrumbs - The array of breadcrumb items.
	 * @returns An array of BreadcrumbItem objects representing the breadcrumbs.
	 */
	private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
		const newBreadcrumbs = [...breadcrumbs];
		const path = route.snapshot.url.map(segment => segment.path).join('/');
		const nextUrl = `${url}/${path}`.replace('//', '/');
		if (route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
			let data = '';

			if (route.routeConfig.data['breadcrumb'][0] === '@') {
				route.routeConfig.data['breadcrumb'].split('.').forEach((level: string, index: number) => {
					if (index === 0) {
						data = route.snapshot.data[level.substr(1)];
					} else {
						data = !!data ? (data as any)[level] : null;
					}
				});
			} else {
				data = route.routeConfig.data['breadcrumb'];
			}

			newBreadcrumbs.push({
				label: data,
				path: nextUrl
			});
		}

		if (route.firstChild) {
			return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
		}

		return newBreadcrumbs;
	}


	/**
	 * Check if maintenance page should be displayed
	 */
	checkMaintenance() {
		console.log('SETTINGSSERVICE checkmaintenance()');
		return this.backApiService.getData(`${environment.maintenanceUrl}`, false).pipe(tap((res: any) => {
			console.log('SETTINGSSERVICE checkmaintenance() res');
			console.log(res);
			if (res?.appVersion && res?.minAppVersion) {
				if (this.platformService.is('ios')) {
					if (environment.appVersion < res?.minAppVersion?.ios) {
						this.outdatedVersion.next('force');
					}
					else if (environment.appVersion < res?.appVersion?.ios) {
						this.outdatedVersion.next(true);
					}
				} else if (this.platformService.is('android')) {
					{
						if (environment.appVersion < res?.minAppVersion?.android) {
							this.outdatedVersion.next('force');
						}
						else if (environment.appVersion < res?.appVersion?.android) {
							this.outdatedVersion.next(true);
						}
					}
				}
			}
		}));
	}

	/**
	 * Check if the application version is outdated
	 * @return {Observable<any>} Observable for outdated version status
	 */
	checkOutdated() {
		return this.outdatedVersion.asObservable();
	}

	/**
	 * Get observable for rate app prompt
	 * @return {Observable<boolean>} Observable for rate app prompt
	 */
	getRateApp() {
		return this.shouldRate.asObservable();
	}

	/**
	 * Sets the rate app value.
	 * 
	 * @param value - The boolean value to set for rate app.
	 */
	setRateApp(value: boolean) {
		this.rateEvents.rated = value;
		this.storage.set('rateEvents', this.rateEvents);
		console.log("SETTINGSSERVICE setRateApp()");
		console.log(this.rateEvents);
	}

	/**
	 * Sets the rate event based on the given type.
	 * 
	 * @param type - The type of rate event.
	 */
	setRateEvent(type: string) {
		console.log("SETTINGSSERVICE setRateEvent()");
		console.log(type);
		console.log(this.rateEvents);
		if (!this.rateEvents.rated && !environment.isPwa) {
			switch (type) {
				case 'sendMsg':
					this.rateEvents.nbRateEvent++;
					break;
				case 'deleteOffer-provided-cbo':
					this.rateEvents.nbRateEvent += 2;
					break;
				default:
					break;
			}
			if (this.rateEvents.nbRateEvent == 2 && this.rateEvents.nbRateRequests == 0) {
				this.rateEvents.nbRateRequests++;
				this.rateEvents.lastRateRequest = new Date();
				this.shouldRate.next(true);
			} else if (this.rateEvents.nbRateEvent >= 4 && this.rateEvents.nbRateRequests < 2 && (new Date()).getTime() - new Date(this.rateEvents.lastRateRequest).getTime() > 86400000) {
				this.rateEvents.nbRateRequests++;
				this.rateEvents.lastRateRequest = new Date();
				this.shouldRate.next(true);
			} else if (this.rateEvents.nbRateEvent >= 6 && this.rateEvents.nbRateRequests < 3 && (new Date()).getTime() - new Date(this.rateEvents.lastRateRequest).getTime() > 2464000000) {
				this.rateEvents.nbRateRequests++;
				this.rateEvents.lastRateRequest = new Date();
				this.shouldRate.next(true);
			}
			this.storage.set('rateEvents', this.rateEvents);
		}
	}

	setSsoApple(ssoApple: boolean) {
		this.ssoApple.next(ssoApple);
	}

	/**
	 * Get observable for SSO Apple status
	 * @return {Observable<any>} Observable for SSO Apple status
	 */
	getSsoApple() {
		return this.ssoApple.asObservable();
	}

	/**
	 * Add a subscription to the list of managed subscriptions
	 * @param {Subscription} subs - The subscription to add
	 */
	addSubscribtion(subs: Subscription) {
		this.subscribtions.push(subs);
	}

	/**
	 * Clears all subscriptions in the `subscribtions` array.
	 */
	clearSubscribtions() {
		console.log("SETTINGSSERVICE clearSubscribtions");
		this.subscribtions.forEach(subs => {
			if (subs) {
				subs.unsubscribe();
			}
		});
		console.log("SETTINGSSERVICE clearSubscribtions =");
		console.log(this.subscribtions);
	}

	setUserRole(role: string | null) {
		this.userRole = role;
		this.userRoleObs.next(role);
		this.roleLoaded = true;
		this.dataLayerService.setUserRole(role);
		console.log("Settings service : userRoleLoaded");
		console.log(this.userRole);
	}

	getUserRoleObs() {
		return this.userRoleObs;
	}

	/**
 * Check for SSO login observable
 * @return {BehaviorSubject<any>} Observable for SSO login status
 */
	checkForSsoLogin() {
		return this.ssoLoginObs;
	}

	/**
 * Get observable for application mode
 * @return {BehaviorSubject<AppMode>} Observable for application mode
 */
	getAppMode() {
		return this.appModeObs;
	}

	/**
 * Set the application mode
 * @param {AppMode} mode - The new application mode
 */
	setAppMode(mode: AppMode) {
		this.appModeObs.next(mode);
	}

	/**
 * Detect and set the application mode based on user role and URL
 */
	detectAppMode() {
		combineLatest([this.userRoleObs, this.activePageObs]).subscribe(([role, activePage]) => {
			console.log("SETTINGS detectAppMode()");
			console.log("url : " + JSON.parse(JSON.stringify(this.router.url)));
			console.log(role);
			console.log(activePage);
			if (role) {
				if (role === 'ROLE_TRANSACTION' || this.router?.url?.includes('transaction')) {
					this.setAppMode('transaction');
				} else if (role === 'ROLE_RECRUITER') {
					this.setAppMode('recruiter');
				} else {
					this.setAppMode('candidate');
				}
			} else if (activePage?.includes('transaction')) {
				this.setAppMode('transaction-offline');
			} else {
				this.setAppMode('job-offline');
			}
			console.log("SETTINGS detectAppMode() appMode =");
			console.log(this.appModeObs.value);
		});
	}

}

